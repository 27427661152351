import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

import '../styles/contact.css'

import { ImLocation2 } from 'react-icons/im'
import { FaPaperPlane } from 'react-icons/fa'
import { BsFillTelephoneFill } from 'react-icons/bs'
// import { MdLocationOn } from 'react-icons/md'
import { FaFax } from 'react-icons/fa'

import img1 from '../Assets/home/image19.jpg'
import img2 from '../Assets/home/image1.jpg'
import img3 from '../Assets/home/image17.jpg'

import img4 from '../Assets/home/image22.jpg'
import img5 from '../Assets/home/image15.jpg'
import img6 from '../Assets/home/image13.jpg'


const photosData = [
  {
    id: 1,
    image: img1,
  },
  {
    id: 2,
    image: img2,
  },
  {
    id: 3,
    image: img3,
  },
  {
    id: 4,
    image: img4,
  },
  {
    id: 5,
    image: img5,
  },
  {
    id: 6,
    image: img6,
  }
]

const contactData = [
  {
    id: 1,
    Icon: ImLocation2,
    title: 'Address',
    subTitle: '4-198 Mcphillips Street, Winnipeg, MB R3E 2J9',
  },
  {
    id: 2,
    Icon: FaPaperPlane,
    title: 'Email Address',
    subTitle: 'mcphillipspharmacywpg@gmail.com',
  },
  {
    id: 3,
    Icon: BsFillTelephoneFill,
    title: 'Phone Number',
    subTitle: '204-786-3468',
  },
  {
    id: 4,
    Icon: FaFax,
    title: 'Fax Number',
    subTitle: '204-786-3465',
  }
]

const Contact = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [statusMessage, setStatusMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    console.log("message sent");

    const SERVICE_ID = 'service_lle3sme';
    const TEMPLATE_ID = 'template_pm5v5hp';
    const PUBLIC_KEY = 'mNiJeJ2XUJEujqJoH';

    //create a new object that contains dynamic template parms
    const templateParams = {
      user_name: name,
      user_email: email,
      user_subject: subject,
      user_message: message,
      to_name: 'Dalwinder',
    };

    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, PUBLIC_KEY)
      .then((result) => {
        console.log(result.text);
        console.log("Email sent successfully", result);
        setStatusMessage("Email sent..")

        setTimeout(() => {
          setStatusMessage("")
        }, 5000) // Hide the message after 5 seconds

        setName('');
        setEmail('');
        setSubject('');
        setMessage('');

      }, (error) => {
        console.log(error.text);

        setStatusMessage("Email Failed..")
        setTimeout(() => {
          setStatusMessage("")
        }, 5000) // Hide the message after 5 seconds
      });
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log(event.target.user_name.value);
  //   console.log(event.target.user_email.value);
  //   console.log(event.target.user_subject.value);
  //   console.log(event.target.user_message.value);
  // }

  return (
    <div className='contact'>

      <div className="secContainer container grid">

        <div className="contactTitle">
          <h1>Contact Us</h1>
        </div>

        <div className="contactList">
          {
            contactData.map(({ id, Icon, title, subTitle }) => {
              return (
                <div key={id} className="contactItem">
                  <div className="contactIcon">
                    <Icon className="icon" />
                  </div>
                  <h2>{title}</h2>
                  <h3>{subTitle}</h3>
                </div>
              )
            })
          }
        </div>

        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2569.354946252205!2d-97.17381708438346!3d49.910912579404524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52ea73cf4d799ecb%3A0xd58ad46a5bf9a689!2s198%20McPhillips%20St%20%234%2C%20Winnipeg%2C%20MB%20R3E%202K2!5e0!3m2!1sen!2sca!4v1676450286513!5m2!1sen!2sca"
            title="pharmacy address map"
            width="100%"
            height="400"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          >
          </iframe>
        </div>

        <div className="contactForm">
          <div className="formTitle">
            <h2>Send Us A Message</h2>
          </div>
          <form className="contactUsForm" onSubmit={sendEmail}>
            <input
              className="contactInput"
              type="text"
              name="name"
              value={name}
              placeholder="Your Name" required
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="contactInput"
              type="email"
              name="email"
              value={email}
              placeholder="Your Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="contactInput"
              type="text"
              name="subject"
              value={subject}
              placeholder="Subject"
              onChange={(e) => setSubject(e.target.value)}
            />
            <textarea
              className="contactInput"
              type="text"
              name="message"
              value={message}
              rows="6"
              placeholder="Your Message"
              onChange={(e) => setMessage(e.target.value)}
            >
            </textarea>
            <div className="buttonAndStatus">
              <button
                className="btn btn1"
                type="submit"
              >
                Submit
              </button>
              <h2>
                {statusMessage}
              </h2>
            </div>
          </form>
        </div>

      </div>

      <div className="photos">
        {
          photosData.map(({ id, image }) => {
            return (
              <div className="photoItem" key={id}>
                <img src={image} alt="image2" />
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Contact
