import React from 'react';
import '../styles/services.css'

import { FaHandHoldingMedical, FaPills } from 'react-icons/fa'
import { IoLogoNoSmoking } from 'react-icons/io'
import { TbTruckDelivery, TbVaccine } from 'react-icons/tb'
import { GiMedicalThermometer, GiAlarmClock, GiBrain } from 'react-icons/gi'
import { MdWifiCalling3, MdMedicalServices } from 'react-icons/md'
import { RiMedicineBottleFill, RiPsychotherapyFill } from 'react-icons/ri'


const serviceData = [
  {
    id: 1,
    Icon: FaPills,
    subTitle: 'Compliance Packaging',
    paragraph: 'Allow us to assist you in remembering to take your medications! Our blister packaging service is free and serves as a helpful reminder of which medications to take and when. Its designed to be easier to open than traditional vials, making it ideal for caregivers and perfect for travel purposes!',
  },
  {
    id: 2,
    Icon: TbTruckDelivery,
    subTitle: 'FREE SAME DAY PRESCRIPTION DELIVERY',
    paragraph: 'To prioritize patient and public safety, our pharmacy will schedule deliveries only when the patient or an authorized agent is available to receive them. A delivery receipt must be signed by the patient or agent to confirm the receipt of medications. Requests to leave delivered drugs in a mailbox or doorway will be considered only in exceptional situations, supported by documented professional judgment and a clear rationale. The potential safety risks associated with such requests will be thoroughly discussed with the patient beforehand.',
  },
  {
    id: 3,
    Icon: GiAlarmClock,
    subTitle: 'MEDICATION REFILL REMINDER',
    paragraph: 'Let us handle the hassle of managing your refills for you. We offer convenient notifications via text or call, based on your communication preference, to remind you when it is time for a refill. We proactively contact your doctor for refill requests in advance, ensuring you are never without your medications. Rest assured, we have got you covered.',
  },
  {

    id: 4,
    Icon: FaHandHoldingMedical,
    subTitle: 'MEDICATION REVIEWS',
    //added the line spliter
    paragraph: 'Our experienced pharmacists are here to assist you with a comprehensive medication review. We will:\n-Evaluate any adverse effects you may have experienced.\n-Provide guidance on the best way to take your medications.\n-Explain the purpose of each medication in your regimen.\n-Discuss potential side effects and strategies to minimize them.\n-Identify and address any issues related to specific medications that may arise.',
  },
  {
    id: 5,
    Icon: GiMedicalThermometer,
    subTitle: 'MINOR AILMENTS PRESCRIBING',
    paragraph: 'Save time by skipping the doctor appointment for a prescription! Our certified pharmacists can prescribe medications for a variety of minor ailments. Contact us today to learn more about how our team can assist you!',
  },
  {
    id: 6,
    Icon: TbVaccine,
    subTitle: 'MEDICATION INJECTIONS OR VACCINATIONS',
    paragraph: 'Save time by avoiding the need to book an appointment with your doctor for an injection! Our team of certified pharmacists is qualified to administer a variety of medication injections and vaccinations. Contact us today for more details on how we can assist you!',
  },
  {
    id: 7,
    Icon: RiMedicineBottleFill,
    subTitle: 'OPIOID REPLACEMENT THERAPY',
    paragraph: 'Our pharmacy is well-prepared to distribute Methadone, Suboxone, and Naloxone kits.',
  },
  {
    id: 8,
    Icon: IoLogoNoSmoking,
    subTitle: 'SMOKING CESSATION PRESCRIBING',
    paragraph: 'Seeking assistance and guidance to quit smoking? Visit our pharmacy where our pharmacists are ready to help you achieve your goal!',
  },
  {
    id: 9,
    Icon: GiBrain/* RiPsychotherapyFill */,
    subTitle: 'DIABETES ADVICE AND GLUCOMETER TRAINING',
    paragraph: 'Are you facing challenges with testing your blood sugar and using your glucose meter? Our pharmacist is here to assist you by demonstrating how to properly use your meter and interpret the results.',
  },
  {
    id: 10,
    Icon: MdMedicalServices,
    subTitle: 'PHARMACIST HOME VISITS',
    paragraph: 'Ensure your safety by scheduling a phone consultation or virtual visit with our pharmacist. We are here to address any questions or concerns you may have about your medications.',
  }
]

const Services = () => {
  return (
    <section className='services'>
      <div className="secContainer container">

        <div className="serviceTitle">
          <h1>Our Healthcare Services</h1>
        </div>

        <div className="serviceList">
          {
            serviceData.map(({ id, Icon, subTitle, paragraph }) => {
              return (
                <div className="serviceItem" key={id}>
                  <div className="serviceIcon">
                    <Icon className="icon" />
                  </div>
                  <h2>{subTitle}</h2>
                  <p>{paragraph}</p>
                </div>
              )
            })
          }
        </div>

      </div>
    </section>
  );
}

export default Services;
